import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import {StaticImage} from "gatsby-plugin-image"
import {Box, Text} from 'theme-ui'
import {Stack, Main, Layout} from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'
import Avatar from '@components/Avatar'

/**
 * Shadow me to add your own content
 */

const styles = {
    imageWrapper: {
        borderRadius: `default`,
        overflow: `hidden`,
        position: `relative`
    },
    button: {
        display: [`none`, `block`],
        position: `absolute`,
        bottom: 4,
        right: 4
    },
    text: {
        fontSize: `1.4rem`
    },
    missionStatement: {
        fontWeight: `bold`,
        fontSize: `1.4rem`
    },
    grid: {
        flexWrap: [`wrap`, null, `nowrap`],
        ' > div + div': {
            ml: [0, 0, 5]
        }
    },
    column: {
        flex: `auto`,
        flexBasis: [`full`, null, `1/2`]
    },
    wrapper: {
        display: [`none`, `block`, 'block'],
        position: `relative`,
        textAlign: `center`
    },
    pattern: {
        height: `1/2`,
        transform: `translate(0, 50%)`
    },
    oogie: {
        width: [`100%`, `75%`, `75%`],
        // margin: `auto`
    }
}

const About = props => {
    const data = useStaticQuery(aboutQuery)

    const image = data && data.avatar && data.avatar.childImageSharp

    return (
        <Layout {...props}>
            <Seo title='About'/>
            <Divider/>
            <Stack>
                <Main>
                    <PageTitle
                        header='About Us'
                    />
                    <Divider/>
                    <Box sx={styles.wrapper}>
                        <Avatar avatar={image} withPattern patternStyles={styles.pattern} loading="eager"/>
                    </Box>
                    <Divider/>
                    <Text variant='p' sx={styles.text}>
                        Welcome to Woof & Beyond!<br/><br/>

                        I'm the founder of this blog, and I am a huge dog lover. I've always had a special connection with these amazing creatures.<br/><br/>

                        Growing up, I was actually afraid of dogs. But something shifted for me when I was around 12 years old. I can't quite put my finger on what it was, but I just suddenly felt drawn to them. From that moment on, I couldn't get enough of them. I started learning everything I could about dogs, and my love and fascination for them has only grown over the years.<br/><br/>

                        Now, as the proud owner of an adorable French Bulldog named Oogie, I understand the joy and responsibility that comes with having a furry friend in your life. They bring so much love and joy into our lives, and it's our job to take care of them and make sure they are happy and healthy.<br/><br/>

                        That's why I'm dedicated to sharing my knowledge and experience with others through Woof & Beyond. I want to help other dog owners create strong, healthy relationships with their furry friends.<br/><br/>

                        Here at Woof & Beyond, we believe in using a hybrid approach to dog training. We combine positive reinforcement with negative reinforcement as needed to address any unwanted behaviors. Our focus is on helping every dog reach their full potential and maintain a strong bond with their owner.<br/><br/>

                        I know that raising a dog can be overwhelming at times, but it can also be one of the most rewarding experiences in life. If you're a dog owner or considering becoming one, I hope that Woof & Beyond can be a helpful resource for you.<br/><br/>

                        Thanks for joining me on this journey! I can't wait to share my knowledge and love for dogs with you.
                    </Text>
                    <Text variant='p' sx={styles.text}>
                        Oh, I almost forgot. Please meet my very adorable yet mischievous <a href="https://www.instagram.com/oogie_and_muffin/" target="_blank" rel="noreferrer noopener">Oogie the Frenchie</a>!
                    </Text>

                    <Text variant='p' sx={styles.oogie}>
                        <br/>
                        <StaticImage src="../../../../content/assets/oogie.jpg" alt="My dog, Oogie the Frenchie."
                                     placeholder="blurred" />
                        <br/>
                        <br/>
                        <br/>
                    </Text>
                </Main>
            </Stack>
        </Layout>
    )
}

const aboutQuery = graphql`
    query UpdatedAboutQuery {
        avatar: file(absolutePath: { regex: "/aviram.png/" }) {
            childImageSharp {
                regular: gatsbyImageData(
                    width: 320
                    height: 320
                    transformOptions: { cropFocus: NORTH }
                    placeholder: TRACED_SVG
                )
            }
        }
    }
`

export default About;